export const styles = {
  appContainer: "max-w-[728px] mx-auto text-center",
  sectionContainer: "flex flex-col h-[90vh] bg-gray-100 mt-10 shadow-xl border relative rounded-lg",
  nav: "flex justify-between items-center bg-gray-800 p-4 border-b rounded-lg",
  heading: "text-2xl text-white font-bold",
  logInButton: "flex gap-2 bg-gray-500 p-3 rounded-md text-white hover:bg-slate-700 transition-all",
  signInMessage: "flex items-center justify-center h-full text-xl text-gray-500",
  main: "flex flex-col p-[10px] relative overflow-y-scroll h-[80vh] mb-20",
  message: "flex items-center shadow-xl m-4 py-2 px-3 rounded-tl-full rounded-tr-full",
  name: "absolute mt-[-4rem] text-gray-600 text-xs",
  textMessage: "text-sm w-full flex justify-between items-center gap-2",
  sent: "bg-[#395dff] text-white flex-row-reverse text-end float-right rounded-bl-full",
  received: "bg-[#e5e5ea] text-black float-left rounded-br-full",
  form: "h-14 flex w-full max-w-[728px] text-xl absolute bottom-0 gap-2",
  input: "w-full rounded-md p-2",
  button: "bg-[#395dff] text-white p-2 rounded-md hover:bg-slate-700 transition-all",
}